<template>
  <div class="reply-input">
    <a-form :model="replyForm" ref="formRef">
      <a-form-item
        label=""
        :name="isEditer ? 'contentText' : 'content'"
        :rules="{
          min: isEditer
            ? communityConfig.answerMin
            : communityConfig.commentMin,
          message: $t('bbs.addpost.content_less_tip', [
            isEditer ? communityConfig.answerMin : communityConfig.commentMin,
          ]),
          // 内容不得少于
          trigger: 'change',
        }"
      >
        <div class="editr-box" v-if="isEditer">
          <wangeditor
            :heigt="150"
            ref="editRef"
            :placeholder="$t('CM_PleaseEnterContent')"
            v-on:getWangEditorValue="getWangEditorValue"
            v-on:getWangEditorText="getWangEditorText"
            :editorValue="replyForm.content"
            :disabled="disabled"
          />
          <!-- 请输入内容 -->
          <div class="num-tips">
            <span
              :class="{
                red: replyForm.contentText.length > communityConfig.answerMax,
              }"
              >{{ replyForm.contentText.length }}</span
            >/{{ communityConfig.answerMax }}
          </div>
        </div>
        <div
          class="disabled-area"
          v-else-if="disabled"
          @click="disabledMsg"
        ></div>
        <a-textarea
          v-else
          :rows="4"
          v-model:value="replyForm.content"
          :placeholder="$t('CM_PleaseEnterContent')"
          showCount
          :maxlength="communityConfig.commentMax"
        />
      </a-form-item>
    </a-form>
    <div class="reply-input-foot">
      <div class="tools disabled" v-if="disabled">
        <div class="tools-item"><PictureOutlined />{{ $t("LB_Picture") }}</div>
        <!-- 图片 -->
        <div class="tools-item" v-if="group.isCommentAnonymous === 1">
          <a-checkbox disabled>
            {{
              subjectType === 2
                ? $t("bbs.anonymous_answer")
                : subjectType > 0
                ? $t("bbs.anonymous_comment")
                : $t("bbs.anonymous_reply")
            }}
          </a-checkbox>
          <!-- 匿名  回答 "评论" : "回复" -->
        </div>
        <div class="tools-item" v-if="communityConfig.isAtAll === 1">
          <!-- 提醒谁看 -->
          <span class="anticon at">@</span>{{ $t("bbs.remind_who_watch") }}
          <p class="anticon tips">（{{ $t("bbs.remind_who_watch_tip") }}）</p>
          <!-- @的人会收到消息提醒） -->
        </div>
      </div>
      <div class="tools" v-else>
        <div class="tools-item" @click="openMedia" v-if="type === 'comment'">
          <PictureOutlined />{{ $t("LB_Picture")
          }}{{
            replyForm.coverImgs.length ? `(${replyForm.coverImgs.length})` : ""
          }}
          <!-- 图片 -->
        </div>
        <div class="tools-item" v-if="group.isCommentAnonymous === 1">
          <a-checkbox v-model:checked="replyForm.isAnonymous">
            {{
              subjectType === 2
                ? $t("bbs.anonymous_answer")
                : subjectType > 0
                ? $t("bbs.anonymous_comment")
                : $t("bbs.anonymous_reply")
            }}
          </a-checkbox>
          <!-- 匿名  回答 "评论" : "回复" -->
        </div>
        <div class="tools-item" v-if="communityConfig.isAtAll === 1">
          <a-dropdown
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :trigger="['click']"
            v-model:visible="dropdownVisible"
          >
            <div>
              <!-- 提醒谁看 -->
              <span class="anticon at">@</span>{{ $t("bbs.remind_who_watch")
              }}{{
                replyForm.remindMembers.length
                  ? `(${replyForm.remindMembers.length})`
                  : ""
              }}
            </div>
            <template #overlay>
              <div class="member-box">
                <a-dropdown
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :trigger="['click']"
                  placement="topLeft"
                >
                  <div>
                    <a-input
                      style="width: 300px"
                      v-model:value="memberVal"
                      :placeholder="$t('bbs.addpost.choose_person')"
                      @change="memberSearch"
                    />
                    <!-- 选择人员 -->
                    <span class="tips">{{
                      $t("bbs.addpost.invite_up_to_nth_people", [10])
                    }}</span>
                    <!-- 最多邀请10位 -->
                  </div>
                  <template #overlay>
                    <a-menu
                      style="max-height: 200px; overflow-y: auto"
                      v-if="memberVal"
                    >
                      <a-spin :spinning="memberLoading">
                        <div style="min-height: 32px">
                          <a-menu-item
                            v-for="item in memberList"
                            :key="item.userId"
                            @click="memberAdd(item)"
                          >
                            <OpenData type="userName" :openid="item.realName" />
                            ({{ item.account }})
                          </a-menu-item>
                          <a-empty
                            v-if="
                              !memberList.length && !memberLoading && memberVal
                            "
                          />
                        </div>
                      </a-spin>
                    </a-menu>
                  </template>
                </a-dropdown>
                <div class="tags">
                  <a-tag
                    color="rgba(0, 0, 0, 0.06)"
                    v-for="(item, index) in replyForm.remindMembers"
                    :key="item.userId"
                  >
                    <OpenData type="userName" :openid="item.realName" />
                    ({{ item.account }})
                    <CloseOutlined @click="memberDel(index)" />
                  </a-tag>
                </div>
              </div>
            </template>
          </a-dropdown>
          <p class="anticon tips">（{{ $t("bbs.remind_who_watch_tip") }}）</p>
          <!-- 的人会收到消息提醒） -->
        </div>
      </div>
      <a-button
        v-if="isEditer"
        type="primary"
        :loading="loading"
        :disabled="
          replyForm.contentText.length <
            (isEditer
              ? communityConfig.answerMin
              : communityConfig.commentMin) ||
          replyForm.contentText.length >
            (isEditer
              ? communityConfig.answerMax
              : communityConfig.commentMax) ||
          disabled
        "
        @click="toReply"
        >发表</a-button
      >
      <!-- 发表 -->
      <a-button
        v-else
        type="primary"
        :loading="loading"
        :disabled="
          replyForm.content.length <
            (isEditer
              ? communityConfig.answerMin
              : communityConfig.commentMin) || disabled
        "
        @click="toReply"
        >发表</a-button
      >
      <!-- 发表 -->
      <modalMedia onlyPics ref="modalMediaRef" @handleOk="addMedia" />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { debounce } from "lodash";
import { getAllMember } from "@/api/bbs";
import { wxworkSearch, dingSearch } from "@/api/other";
import modalMedia from "./modalMedia.vue";
import wangeditor from "@/components/wangeditor/index.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "replyInput",
  components: {
    modalMedia,
    wangeditor,
    OpenData,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    communityConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    group: {
      type: Object,
      default: () => {
        return { adminUids: "" };
      },
    },
    isEditer: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    subjectType: {
      type: Number,
      default: -1,
    },
    type: {
      type: Object,
      default: "comment",
    },
  },
  emits: ["toComment"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const platformConfig = computed(() => store.getters.platformConfig);
    const companyInfo = computed(() => store.getters.companyInfo);
    const formRef = ref(null);
    const state = reactive({
      loading: false,
      dropdownVisible: false,
      memberVal: "",
      memberLoading: false,
      memberList: [],
      replyForm: {
        content: "",
        contentText: "",
        coverImgs: [],
        remindUids: [],
        remindMembers: [],
        isAnonymous: false,
      },
    });

    const editRef = ref(null);
    const init = () => {
      state.loading = false;
      state.memberVal = "";
      if (props.isEditer && editRef) editRef.value.clear();
      state.replyForm = {
        content: "",
        contentText: "",
        coverImgs: [],
        remindUids: [],
        remindMembers: [],
        isAnonymous: false,
      };
    };

    let modalMediaRef = ref(null);
    const openMedia = () => {
      modalMediaRef.value.openModal(state.replyForm);
    };

    const addMedia = ({ pics }) => {
      state.replyForm.coverImgs = pics;
    };

    const memberSearch = debounce(() => {
      if (state.memberVal.trim() == "") return false;
      state.memberLoading = true;
      if (
        platformConfig.value.platform == 26 ||
        platformConfig.value.platform == 31
      ) {
        let searchAjax = wxworkSearch;
        if (platformConfig.value.platform == 31) searchAjax = dingSearch;
        searchAjax(state.memberVal).then((res) => {
          getAllMember({
            page: 1,
            pageSize: 10,
            keyword: state.memberVal,
            accounts: res.data.users || [],
          }).then((res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          });
        });
      } else {
        getAllMember({ page: 1, pageSize: 10, keyword: state.memberVal }).then(
          (res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          }
        );
      }
    }, 500);

    const memberAdd = (data) => {
      if (state.replyForm.remindUids.length >= 10) {
        proxy.$message.warn($t("bbs.select_most_people", [10]));
        // 最多选择10个人员
        return false;
      }
      if (state.replyForm.remindUids.includes(data.userId)) {
        proxy.$message.warn($t("bbs.person_is_select"));
        // 已选择此人员
        return false;
      }
      state.replyForm.remindMembers.push(data);
      state.replyForm.remindUids.push(data.userId);
      state.memberVal = "";
      state.memberList = [];
    };

    const memberDel = (index) => {
      state.replyForm.remindMembers.splice(index, 1);
      state.replyForm.remindUids.splice(index, 1);
    };

    const getWangEditorValue = (str) => {
      state.replyForm.content = str;
    };

    const getWangEditorText = (str) => {
      state.replyForm.contentText = str;
      if (
        str.length >= props.communityConfig.answerMin &&
        str.length <= props.communityConfig.answerMax
      ) {
        formRef.value.clearValidate();
      } else {
        formRef.value.validateFields("contentText");
      }
    };

    const disabledMsg = () => {
      proxy.$message.warn($t("bbs.quesition_has_close"));
      // 该问题已关闭
    };

    const toReply = () => {
      if (props.group.isComment === 2) {
        if (props.group.masterUid === props.userInfo.userId) {
        } else {
          const msg = $t("Pub_Tab_CommentNum") + "/" + $t("CM_QAAnswer");
          proxy.$message.warn($t("bbs.only_owner_can", [msg]));
          // 仅圈主可以评论/回答
          return false;
        }
      } else if (props.group.isComment === 3) {
        if (
          props.group.masterUid === props.userInfo.userId ||
          props.group.adminUids.includes(props.userInfo.userId)
        ) {
        } else {
          const msg = $t("Pub_Tab_CommentNum") + "/" + $t("CM_QAAnswer");
          proxy.$message.warn($t("bbs.only_owner_admin_can", [msg]));
          // 仅圈主或管理员可以评论/回答
          return false;
        }
      }
      state.loading = true;
      let params = JSON.parse(JSON.stringify(state.replyForm));
      params.isAnonymous = params.isAnonymous ? 1 : 0;
      params.remindUids = params.remindUids.join();
      params.coverImgs = params.coverImgs.map((item) => {
        return {
          file: item.exist ? item.md5 : item.file,
          name: item.name,
        };
      });
      emit("toComment", {
        type: props.type,
        params: params,
      });
    };

    return {
      ...toRefs(state),
      formRef,
      init,
      modalMediaRef,
      openMedia,
      addMedia,
      memberSearch,
      memberAdd,
      memberDel,
      editRef,
      getWangEditorValue,
      getWangEditorText,
      disabledMsg,
      toReply,
    };
  },
};
</script>

<style lang="less" scoped>
.reply-input {
  .min-tips {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
    padding-right: 100px;
    margin-top: -22px;
    line-height: 22px;
    text-align: right;
    user-select: none;
  }
  .disabled-area {
    height: 98px;
    background-color: #f5f5f5;
    cursor: not-allowed;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  &-foot {
    .mixinFlex(space-between);
    .tools {
      .mixinFlex();
      height: 32px;
      &-item {
        // .mixinFlex(flex-start; center);
        font-size: 14px;
        color: #666;
        margin-right: 30px;
        transition: color 0.3s;
        cursor: pointer;
        .anticon {
          margin-right: 5px;
          font-size: 18px;
          &.at {
            font-size: 20px;
          }
          &.tips {
            font-size: 14px;
            color: #999;
            vertical-align: baseline;
          }
        }
        &:hover {
          color: @color-theme;
        }
      }
      &.disabled {
        margin-top: -3px;
        .tools-item {
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
      }
    }
  }
}
.member-box {
  max-width: 900px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: default;
  .tips {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 32px;
    padding-left: 20px;
  }
  .tags {
    margin-top: 8px;
    ::v-deep(.ant-tag) {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      .anticon-close {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.editr-box {
  ::v-deep(.w-e-toolbar .w-e-menu) {
    width: 39px !important;
  }
  .num-tips {
    height: 0;
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    .red {
      color: red;
    }
  }
}
</style>
